<template>
  <div id="wf-widgetspanel" class="wf-panel" ref="wfp">
    <div class="wf-panel-tab wfp-t">
      <a href="javascript:;" class="tabitem current">控件</a>
    </div>
    <div class="wf-panel-body wfp-c" style="margin: 10px -15px 0 0;">
      <div
        v-for="(item, index) in components"
        :key="index"
        class="wf-widgetsitem"
        v-bind:data-index="index"
        v-bind:data-type="item.componentName"
        v-on:mousedown="start"
      >
        <label>
          {{ item.name }}
        </label>
        <i class="widgeticon" v-bind:class="item.componentName"></i>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import AppCompoentBase from "@/shared/component-base/app-component-base";

export default {
  name: "mainleftcustomer",
  mixins: [AppCompoentBase],
  data() {
    return {
      components: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initH()
    })
  },
  methods: {
    initH() {
      const height = window.innerHeight - 110;
      this.$refs.wfp.style.height = height + "px";
      this.$emit('mainltop', {h: height})
    },
    start: function (e) {
      let obj = {};
      let dom = e.currentTarget;
      let index = dom.getAttribute("data-index");
      let actualLeft = dom.offsetLeft;
      let current = dom.offsetParent;
      let actualTop = dom.offsetTop;
      while (current !== null) {
        actualLeft += current.offsetLeft;
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      obj.componentName = dom.getAttribute("data-type");
      obj.componentText = dom.querySelector("label").innerText;
      obj.clientX = e.clientX;
      obj.clientY = e.clientY;
      obj.isstart = true;
      obj.componentView = this.components[index];
      console.log(obj);
      drag.$emit("movestart", obj);
    },
    //获取所有表单字段
    getAllFormField() {
      let _this = this;
      this.SendToService({
        url: "/api/services/app/FieldLibs/GetAllFields",
        method: "GET",
        success(res) {
          console.info(res);
          if (res && res.length > 0) {
            res.forEach((item) => {
              _this.components.push(item);
            });
          }
        },
      });
    },
    SendToService(opts) {
      this.spinning = true;
      let options = {
        url: this.$apiUrl + opts.url,
        method: opts.method,
        data: opts.data,
        params: opts.params,
        headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api
        .request(options)
        .then((res) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(res);
          }
          if (res.status == 200) {
            if (opts.success && typeof opts.success == "function") {
              opts.success(res.data);
            }
          } else {
            abp.message.error(res.statusText);
          }
        })
        .catch((e) => {
          console.error(e);
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(e);
          }
        });
    },
  },
  created() {
    this.getAllFormField();
  },
};
</script>

<style lang="less" scoped>
.wf-panel{
    width: 320px;
    padding: 40px 20px 20px;
    // margin: 20px 20px 20px 100px;
    // margin-left: 100px;
    margin-top: 0px;
    position: relative;
  .wfp-t{
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
  }
  .wfp-c{
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
}
</style>
